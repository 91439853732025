import {v4 as uuid} from "uuid";
import Cookies from "js-cookie";
import partnero from "../../../partnero";

export default function visitor() {

    const programId = this.$parent.id;
    const href = window.location.href;
    const debug = this.$parent.$parent.$parent.debug ?? false;
    const partnerKey = partnero.getReferralKeyFromUrl(this.$parent.url_format, this.$parent.link_param, this.$parent.query_param, href);

    // session id
    const sessionUuid = Cookies.get('partnero_session_uuid') || uuid();

    const domainParts = location.hostname.split('.');
    const domainPartsCount = domainParts.length;
    let domain = (domainPartsCount > 1 ? domainParts[domainPartsCount - 2] + '.' : '') + domainParts[domainPartsCount - 1];

    const ccTLDsList = ['co.uk', 'ac.uk', 'com.au', 'gov.au', 'edu.au', 'org.au', 'co.at', 'or.at', 'avocat.fr', 'aeroport.fr', 'co.nz', 'org.nz', 'co.in', 'gov.in', 'edu.in', 'co.jp', 'ac.jp', 'com.ru', 'org.ru', 'net.ru', 'co.za', 'co.kr', 'or.kr', 'com.es', 'org.es', 'com.lk', 'org.lk', 'co.th', 'or.th', 'com.tr', 'edu.tr', 'com.ua', 'edu.ua', 'ca.us', 'ny.us', 'com.pl', 'com.br', 'platformsh.site', 'com.mx'];
    if (ccTLDsList.includes(domain) && domainPartsCount > 2) {
        const glueParts = [domainParts[domainPartsCount - 3], domainParts[domainPartsCount - 2], domainParts[domainPartsCount - 1]];
        domain = glueParts.join('.');
    }

    const {hostname} = location;
    const {screen: {width, height}, navigator: {language}} = window;

    if (debug) {
        console.log('partnero: domain: ', domain);
        console.log('partnero: program id: ', programId);
        console.log('partnero: partner key: ', partnerKey);
        console.log('partnero: sessionUuid: ', sessionUuid);
    }

    // extend lifetime of cookie even if it's been set
    Cookies.set('partnero_session_uuid', sessionUuid, {expires: 3650, domain: domain, SameSite: 'None', Secure: true});

    const host = this.$parent.$parent.$parent.host;
    const simplePageViewsTrackingEnabled = this.$parent.simple_tracking_enabled;
    let currentRef = document.referrer;

    let data = {
        pub_id: programId,
        url: href,
        session_uuid: sessionUuid,
        hostname: hostname,
        screen: `${width}x${height}`,
        language: language,
        referrer: currentRef,
        pageType: 'simple_visit'
    };

    const programType = this.$parent.type;
    const cookieNameByProgramType = partnero.getCookieNameByProgramType(programType);

    if (debug) {
        console.log('partnero: program type: ', programType);
    }

    if (partnerKey) {

        partnero.setReferralKey(partnerKey, programId);

        if (debug) {
            console.log('partnero: cookie name: ', cookieNameByProgramType);
            console.log('partnero: setting partner key cookie');
        }

        Cookies.set(cookieNameByProgramType, partnerKey, {expires: this.$parent.cookie_time, domain: domain, SameSite: 'None', Secure: true});
        Cookies.set('referral_url', href, {expires: this.$parent.cookie_time, domain: domain, SameSite: 'None', Secure: true});
        data = {...data, partner: partnerKey, pageType: 'referred_url'};

        const queryParams = Object.keys(data).map(function (key) {
            return key + '=' + encodeURIComponent(data[key]);
        }).join('&');

        function sendAnalyticsData(keepSessionAlive = false) {
            if (data.pageType === 'simple_visit' && !simplePageViewsTrackingEnabled) {
                return;
            }
            if (keepSessionAlive) {
                //(new Image()).src = `${host}/analytics/refresh-session?session_uuid=${sessionUuid}&partner=${partnerKey}&url=${href}`;
            } else {

                if (debug) {
                    console.log('partnero: tracking pixel request');
                }

                (new Image()).src = `${host}/analytics/pageview?${queryParams}`;
            }
        }

        sendAnalyticsData();

        /*
        setInterval(() => {
            sendAnalyticsData(true);
        }, 60 * 1000);*/
    } else if (debug) {
        console.log('partnero: partner key not found');
    }
}
